import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined'
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import MessageIcon from '@mui/icons-material/Message'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { Box, Button, Card, Modal, TextField, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { addDays, addHours, format } from 'date-fns' // eslint-disable-line import/no-duplicates
import ja from 'date-fns/locale/ja' // eslint-disable-line import/no-duplicates
import React from 'react'
import useSWR, { useSWRConfig } from 'swr'

import { KarteComponent } from '@/components/common/KarteComponent'
import { ChildNameCell } from '@/components/pages/index/TableCell/ChildNameCell'
import { KarteCell } from '@/components/pages/index/TableCell/KarteCell'
import { NextKarteCell } from '@/components/pages/index/TableCell/NextKarteCell'
import { Scalars } from '@/graphql/models'
import { Child } from '@/models/Child'
import { IndividualLessonKarte } from '@/models/IndividualLessonKarte'
import { IndividualLessonSchedule } from '@/models/IndividualLessonSchedule'
import { Karte, karteContentSwitcher } from '@/models/Karte'
import { LessonKarte } from '@/models/LessonKarte'
import { ProblemStock } from '@/models/ProblemStock'
import { RestKarte } from '@/models/RestKarte'

type KarteStatusResponse = {
  children: Child[]
  date: Karte[]
  nextDate: Karte[]
  problemStockList: ProblemStock[]
  individualLessonScheduleList: IndividualLessonSchedule[]
}
export const DailyKarteStatus = () => {
  const { mutate } = useSWRConfig()
  const today = format(addHours(new Date(), -5), 'yyyy-MM-dd')
  const [selectedDate, setSelectedDate] = React.useState<Scalars['Date']>(today)
  const [modalOpen, setModalOpen] = React.useState<Karte | null>(null)
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [columns, setColumns] = React.useState<GridColDef[]>([])
  const [searchName, setSearchName] = React.useState<string>('')

  const { data: karteStatus } = useSWR<KarteStatusResponse>(`/api/daily_karte_status?date=${selectedDate}`)

  const handleOpen = (karte: Karte) => setModalOpen(karte)
  const handleClose = () => {
    mutate(`/api/daily_karte_status?date=${selectedDate}`, false)
    setModalOpen(null)
  }

  const handlePrevDate = (): void => {
    const prev = format(addDays(new Date(selectedDate), -1), 'yyyy-MM-dd')
    setSelectedDate(prev)
  }

  const handleNextDate = (): void => {
    const next = format(addDays(new Date(selectedDate), 1), 'yyyy-MM-dd')
    setSelectedDate(next)
  }

  React.useEffect(() => {
    if (karteStatus) {
      const dateList = new Map(karteStatus.date?.map((v) => [v.childId, v]))
      const nextDateList = new Map(karteStatus.nextDate?.map((v) => [v.childId, v]))
      const individualLessonScheduleList = new Map(karteStatus.individualLessonScheduleList?.map((v) => [v.childId, v]))
      const rows =
        karteStatus.children
          .filter((child) => child.isTest === false && child.name.includes(searchName))
          .map((child) => ({
            id: child.id,
            childId: child.id,
            childName: child.name,
            karte: dateList.get(child.id) ?? null,
            nextKarte: nextDateList.get(child.id) ?? null,
            individualLessonSchedule: individualLessonScheduleList.get(child.id) ?? null,
          })) ?? []

      setRows(rows.sort((a, b) => (a.nextKarte ? 'z' : 'a').localeCompare(b.nextKarte ? 'z' : 'a')))
    }

    setColumns([
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'right',
        align: 'right',
        width: 70,
        renderHeader: () => <Typography sx={{ fontWeight: 'bold' }}>ID</Typography>,
      },
      {
        field: 'childName',
        headerName: '生徒',
        headerAlign: 'left',
        minWidth: 200,
        renderHeader: () => <Typography sx={{ fontWeight: 'bold' }}>生徒</Typography>,
        renderCell: (params) => <ChildNameCell childId={params.row.childId} childName={params.row.childName} />,
        sortComparator: (v1, v2) => v1.localeCompare(v2),
      },
      {
        field: 'karte',
        headerName: '今日のカルテ状態',
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        renderHeader: () => (
          <Typography sx={{ fontWeight: 'bold' }}>{format(new Date(selectedDate), 'M月dd日')}分のカルテ状態</Typography>
        ),
        renderCell: (params) => <KarteCell karte={params.row.karte} />,
      },
      {
        field: 'nextKarte',
        headerName: '次の日のカルテ',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        renderHeader: () => <Typography sx={{ fontWeight: 'bold' }}>次の日のカルテ</Typography>,
        renderCell: (params) => (
          <NextKarteCell childId={params.row.childId} nextKarte={params.row.nextKarte} selectedDate={selectedDate} />
        ),
      },
      {
        field: 'comment',
        headerName: 'トレーナーコメント',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        renderHeader: () => <Typography sx={{ fontWeight: 'bold' }}>トレーナーコメント</Typography>,
        renderCell: (params) => <>{params.row.karte && commentElm(params.row.karte, handleOpen)}</>,
      },
      {
        field: 'individualLessonSchedule',
        headerName: '個別指導予約日時',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        renderHeader: () => <Typography sx={{ fontWeight: 'bold' }}>個別指導予約日時</Typography>,
        renderCell: (params) => (
          <>
            {params.row.individualLessonSchedule &&
              format(new Date(params.row.individualLessonSchedule.lessonAt), 'MM月dd日(E) HH時mm分', { locale: ja })}
          </>
        ),
        sortComparator: (v1, v2) => (v1?.lessonAt ?? '').localeCompare(v2?.lessonAt ?? ''),
      },
    ])
  }, [karteStatus, selectedDate, searchName])

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <Button variant="contained" sx={{ p: 0 }} onClick={handlePrevDate}>
          <ArrowLeftOutlinedIcon />
        </Button>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            mx: 2,
          }}
        >
          {format(new Date(selectedDate), 'M月dd日')}
        </Typography>
        <Button variant="contained" sx={{ p: 0 }} onClick={handleNextDate}>
          <ArrowRightOutlinedIcon />
        </Button>
        <TextField
          sx={{ ml: 2, backgroundColor: '#FFF' }}
          name="filterWord"
          variant="outlined"
          size="small"
          placeholder="名前で絞り込み"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          autoComplete="address-line3"
        />
      </Box>
      <Card sx={{ p: 0 }}>
        <DataGrid
          sx={{ backgroundColor: '#FFF' }}
          rows={rows}
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
        />
      </Card>

      <Modal
        open={modalOpen !== null}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            height: '80vh',
            overflow: 'scroll',
            bgcolor: 'background.paper',
            border: '2px solid #37A2C4',
            p: 1,
          }}
        >
          {modalOpen !== null && <KarteComponent karte={modalOpen} editable={true} afterSubmit={handleClose} />}
        </Box>
      </Modal>
    </Box>
  )
}

const commentElm = (karte: Karte, handleOpen: (karte: Karte) => void) => {
  const lessonKartElm = (content: LessonKarte) => {
    if (content.comment) {
      return (
        <Typography
          onClick={() => handleOpen(karte)}
          variant="body2"
          sx={{ color: '#1876d1', textDecoration: 'underline', cursor: 'pointer' }}
        >
          コメント済
        </Typography>
      )
    } else {
      if (content.problems) {
        return (
          <Typography
            onClick={() => handleOpen(karte)}
            variant="body2"
            sx={{ color: 'red', textDecoration: 'underline', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <HighlightOffIcon fontSize={'small'} /> 要返信
            {content.draftComment && (
              <>
                <SmartToyIcon fontSize={'small'} />
                <MessageIcon fontSize={'small'} />
              </>
            )}
          </Typography>
        )
      } else {
        return (
          <Typography
            onClick={() => handleOpen(karte)}
            variant="body2"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            コメントする
          </Typography>
        )
      }
    }
  }
  const individualKartElm = (content: IndividualLessonKarte) => {
    if (content.doneComment) {
      return (
        <Typography
          onClick={() => handleOpen(karte)}
          variant="body2"
          sx={{
            color: '#1876d1',
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlineIcon fontSize={'small'} />
          済み
        </Typography>
      )
    } else {
      return (
        <Typography
          onClick={() => handleOpen(karte)}
          variant="body2"
          sx={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
        >
          未
        </Typography>
      )
    }
  }
  const restKarteElm = (content: RestKarte) => {
    return (
      <Typography
        onClick={() => handleOpen(karte)}
        variant="body2"
        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        {content.comment && '済み'}
        {!content.comment && '-'}
      </Typography>
    )
  }

  return karteContentSwitcher(karte, lessonKartElm, individualKartElm, restKarteElm)
}
