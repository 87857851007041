import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Typography } from '@mui/material'
import { format } from 'date-fns' // eslint-disable-line import/no-duplicates
import Link from 'next/link'
import React from 'react'

import { Scalars } from '@/graphql/models'
import { Karte } from '@/models/Karte'

type Props = {
  childId: number
  nextKarte: Karte
  selectedDate: Scalars['Date']
}

export const NextKarteCell = (props: Props) => {
  return (
    <Link
      href={`/child/[id]/nextkarte?date=${format(new Date(props.selectedDate), 'yyyy-MM-dd')}`}
      as={`/child/${props.childId}/nextkarte?date=${format(new Date(props.selectedDate), 'yyyy-MM-dd')}`}
      passHref
    >
      {props.nextKarte ? (
        <Typography
          variant="body2"
          sx={{
            color: '#10a835',
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlineIcon fontSize={'small'} />
          作成済
        </Typography>
      ) : (
        <Typography
          variant="body2"
          sx={{ color: 'red', textDecoration: 'underline', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <HighlightOffIcon fontSize={'small'} /> 未作成
        </Typography>
      )}
    </Link>
  )
}
