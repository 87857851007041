import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Typography } from '@mui/material'
import { format } from 'date-fns' // eslint-disable-line import/no-duplicates
import React from 'react'

import { Karte, karteContentSwitcher } from '@/models/Karte'
import { LessonKarte } from '@/models/LessonKarte'

type Props = {
  karte: Karte
}

export const KarteCell = (props: Props) => {
  if (!props.karte) {
    return (
      <Typography variant="body2" sx={{ color: 'red', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <WarningAmberIcon fontSize={'small'} />
        カルテ未作成
      </Typography>
    )
  }

  return karteContentSwitcher(
    props.karte,
    (content: LessonKarte) => {
      if (content.filledAt) {
        return (
          <Typography variant="body2" sx={{ color: '#10a835', display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineIcon fontSize={'small'} />
            提出済（{format(new Date(content.filledAt), 'MM/dd HH:mm')}）
          </Typography>
        )
      } else {
        return (
          <Typography variant="body2" sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
            未提出
          </Typography>
        )
      }
    },
    () => (
      <Typography variant="body2" sx={{ color: '#10a835', display: 'flex', alignItems: 'center' }}>
        個別指導
      </Typography>
    ),
    () => (
      <Typography variant="body2" sx={{ color: '#10a835', display: 'flex', alignItems: 'center' }}>
        お休み
      </Typography>
    )
  )
}
