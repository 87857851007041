import { Container } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'

import { DailyKarteStatus } from '@/components/pages/index/DailyKarteStatus'
import { trainerContext } from '@/context/trainer'

const App = () => {
  const { trainer } = useContext(trainerContext)
  const router = useRouter()

  useEffect(() => {
    if (trainer?.role === 'tutor') {
      router.replace('/individualLesson')
    }
  }, [router.pathname])

  if (trainer?.role !== 'master') {
    return <>Loading...</>
  }

  return (
    <Container
      sx={{
        my: '50px',
        p: 0,
      }}
    >
      <DailyKarteStatus />
    </Container>
  )
}

App.requireAuth = true

export default App
