import { Typography } from '@mui/material'
import Link from 'next/link'
import React from 'react'

type Props = {
  childId: number
  childName: string
}

export const ChildNameCell = (props: Props) => {
  return (
    <Link href="/child/[id]" as={`/child/${props.childId}`} passHref>
      <Typography
        sx={{
          color: '#1876d1',
          cursor: 'pointer',
        }}
      >
        {props.childName}
      </Typography>
    </Link>
  )
}
